import { Box } from "@mui/material";
import React from "react";
import Email from "./Email";

const ProfileAlerts = () => {
  return (
    <Box>
      {/* <Vk /> */}
      <Email />
    </Box>
  );
};

export default ProfileAlerts;
